import * as React from "react";
import * as styles from "./index.module.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerText}>
          <h1>Diane Saint-Pierre, sca</h1>
          <h2>Aquarelliste</h2>
        </div>
        <ul className={styles.links}>
          <li>
            <a href="/galerie">Galerie</a>
          </li>
          <li>
            <a href="#demarche">D&eacute;marche artistique</a>
          </li>
          <li>
            <a href="/about">Réalisations</a>
          </li>
          <li>
            <a href="/contact">Contactez-moi</a>
          </li>
        </ul>
      </div>
      <div className={styles.demarche} id="demarche">
        <div className={styles.demarcheInner}>
          <h3>D&eacute;marche artistique</h3>
          <p>
            C'est avec la couleur que mon élan est avant tout satisfait. Elle me
            permet de laisser transparaître mes états d'âme. L'harmonie des
            teintes est essentielle dans mon travail et la lumière est
            omniprésente. Pour moi, les tableaux sombres et tristes n'ont pas
            droit de cité. Pour l'essentiel de mon oeuvre, c'est la nature qui
            m'inspire. La gestuelle est un autre élément que je privilégie.
            C'est dans des grands formats que je m'exprime le mieux. J'ai ainsi
            toute la liberté voulue pour expérimenter et laisser libre cours à
            la créativité. J'aime beaucoup explorer de nouveaux supports pour
            aller plus loin dans la technique de l'aquarelle.
          </p>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
